import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';

const UserDetailForm = ({ userDetails, handleUserDetailsChange, setFormData, validationErrors }) => (
    <>
        {Object.keys(userDetails).map((key, idx) => (
            <>
                {key === "consent" ?
                    <Form.Group className="mb-3" key={idx}>
                        <Form.Check type="switch" id="custom-switch" checked={userDetails.consent} onChange={() => setFormData(prevState => ({ ...prevState, userDetails: { ...prevState.userDetails, consent: !prevState.userDetails.consent } }))}>
                            <Form.Check.Input type="checkbox" className="me-2" />
                            <Form.Check.Label htmlFor="custom-switch">
                                I agree that my contact information will only be used for the purpose of responding to this submission.
                            </Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                    :
                    <Form.Group className="col-12 mb-3" key={key}>
                        <Form.Control
                            type={key === "emailAddress" ? "email" : "text"}
                            name={key}
                            value={userDetails[key]}
                            onChange={handleUserDetailsChange}
                            placeholder={`Enter ${key}`}
                            className="p-3 rounded-pill border-0 fst-italic fw-bold"
                            isInvalid={!!validationErrors[key]}
                        />
                        <Form.Control.Feedback type="invalid">
                            {validationErrors[key]}
                        </Form.Control.Feedback>
                    </Form.Group>
                }
            </>
        ))}
    </>
);

function SubmissionForm({ packageCard = false, formData, handleUserDetailsChange, setFormData, validationErrors, setValidationErrors, handleSubmitForm }) {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const validateUserDetails = () => {
        const errors = {};
        const requiredFields = ['name', 'addressLine1', 'postCode', 'city', 'emailAddress', 'phoneNumber'];
        
        requiredFields.forEach(field => {
            if (!formData.userDetails[field]) {
                errors[field] = `${field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} is required`;
            }
        });
        
        // Additional validation for email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.userDetails.emailAddress && !emailRegex.test(formData.userDetails.emailAddress)) {
            errors.emailAddress = "Please enter a valid email address";
        }
        
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Run validation before submitting
        if (!validateUserDetails()) {
            return; // Stop if validation fails
        }

        try {
            const response = await fetch('https://s8tts9clqf.execute-api.eu-west-1.amazonaws.com/dev/submission', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setModalContent({ title: 'Quote sent!', message: 'We will be in touch soon. We have sent a copy to your email too.', variant: 'success' });
            } else {
                setModalContent({ title: 'Oops!', message: 'An error occurred while processing your quote. Please try again.', variant: 'danger' });
            }
        } catch (error) {
            setModalContent({ title: 'Oops!', message: 'An error occurred while processing your quote. Please try again.', variant: 'danger' });
        } finally {
            setShowModal(true);
        }
    };

    return (
        <Col as="section" xs={12} className="bg-niklas-gradient rounded-5 p-2 p-md-5">
            <h2 className="fs-1 fw-bold text-white text-center py-3 py-sm-5">Let us connect</h2>
            <Container style={{ maxWidth: "680px" }}>
                <Row>
                    <Col className={packageCard ? "col-12 col-sm-8" : "col-12 row"}>
                        <Form onSubmit={handleSubmit}>
                            <UserDetailForm
                                userDetails={formData.userDetails}
                                handleUserDetailsChange={handleUserDetailsChange}
                                setFormData={setFormData}
                                validationErrors={validationErrors}
                            />

                            <Button
                                size="lg"
                                className="bg-special-gradient m-2 fw-bold fst-italic text-center rounded-pill"
                                type="submit"
                                onClick={handleSubmitForm}
                            >
                                Submit
                            </Button>
                        </Form>
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{modalContent.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{modalContent.message}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                    <Col className={packageCard ? "col-12 col-sm-4" : "col-12 row"}>
                        {packageCard && packageCard}
                    </Col>
                </Row>
            </Container>
        </Col>
    )
};

export default SubmissionForm;