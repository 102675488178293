import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import ViewQuestions from './QuizForm/View_Questions';
import ViewPackages, { PackageCard } from './QuizForm/View_Packages';
import ViewSubmission from './QuizForm/View_Submission'

const steps = [
    {
        title: "Choose your service:",
        options: [{
            window_cleaning: "Window Cleaning",
            gutter_cleaning: "Gutter Cleaning",
        }],
        field: "serviceType"
    },
    {
        title: "Type of property:",
        options: [{
            detached: "Detached",
            semi_detached: "Semi-Detached",
            terraced: "Terraced",
            apartment: "Apartment",
            bungalow: "Bungalow",
            townhouse: "Townhouse",
        }],
        field: "propertyType",
        exlusions: {
            terraced: ["three_storey"],
            bangalow: ["two_storey", "three_storey"],
            apartment: ["two_storey", "three_storey", "conservatory", "extension"]
        }
    },
    {
        title: "Bedrooms:",
        options: [{
            two: "2",
            three: "3",
            four: "4",
            five: "5",
            five_plus: "5+"
        }],
        field: "bedroomCount"
    },
    {
        title: "Any features?",
        options: [{
            "conservatory": "Conservatory",
            "extension": "Extension",
            "two_storey": "Two storey",
            "three_storey": "Three storey",
            "none": "No features"
        }],
        field: "propertyFeature"
    }
];

const packages = [{
    clear: {
        name: "Gutter & Downspout Clear",
        type: "clear",
        price: "TBC",
        features: ["All Gutters Cleared", "Before & After pictures", "Timed Appointment", "Cardless, Cash, Cheque"],
        gradient: "bg-niklas-gradient",
        textColor: "text-black"
    },
    clean: {
        name: "Soffit & Fascia Clean",
        type: "clean",
        price: "TBC",
        features: ["Soffit/Fascia Clear", "Before & After pictures", "Timed Appointment", "Cardless, Cash, Cheque"],
        gradient: "bg-niklas-gradient",
        textColor: "text-black"
    },
    full: {
        name: "Full Service",
        type: "full",
        price: "TBC",
        features: ["All Gutter & Downspout Clear", "All Soffit & Fascia Clean", "Extra UPVC & Door Clean"],
        gradient: "bg-special-gradient",
        textColor: "text-black"
    }
}];


const QuoteQuiz = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [validationErrors, setValidationErrors] = useState({});
    const [formData, setFormData] = useState({
        serviceType: "",
        propertyType: "",
        bedroomCount: "",
        propertyFeature: "",
        packageSelection: "",
        userDetails: {
            name: "",
            company: "",
            addressLine1: "",
            postCode: "",
            city: "",
            emailAddress: "",
            phoneNumber: ""
        }
    });

    const handlePrev = () => setCurrentStep(currentStep - 1);

    const handleUserDetailsChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, userDetails: { ...formData.userDetails, [name]: value } });
        // Clear validation error when user types in the field
        if (validationErrors[name]) {
            setValidationErrors({ ...validationErrors, [name]: "" });
        }
    };

    const handleNext = (name, value) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
        setCurrentStep(currentStep + 1);
    };

    const currentSection = steps[currentStep];
    const renderStep = () => {
        switch (currentStep) {
            case steps.length + 1:
                const packageCard = formData.serviceType === "gutter_cleaning" && <PackageCard pkg={formData.packageSelection} />
                return <ViewSubmission 
                    packageCard={packageCard} 
                    formData={formData} 
                    handleUserDetailsChange={handleUserDetailsChange}
                    validationErrors={validationErrors}
                    setValidationErrors={setValidationErrors}
                    setFormData={setFormData}
                />;
            case steps.length:
                if (formData.serviceType !== "gutter_cleaning") {
                    handleNext("packageSelection", "")
                    return
                }
                return <ViewPackages handleNext={handleNext} packages={packages} formData={formData} />;
            default:
                return <ViewQuestions
                    currentSection={currentSection}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    formData={formData}
                />;
        }
    }

    return (
        <Container className='my-5' id="getYourQuotePage">
            <Row className="py-5 d-flex align-items-center">
                <Col xs={12} className="text-center">
                    <h1 className="display-4 fw-bold pb-4">Quote time</h1>
                </Col>
            </Row>
            <Row>
                {renderStep()}
            </Row>
        </Container>
    )
}

export default QuoteQuiz;